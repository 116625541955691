<script>
import axios from "axios";
import Swal from "sweetalert2";
import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";
// import { mapState } from "vuex";

import appConfig from "@/app.config";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import $ from "jquery";
import Cookies from 'js-cookie';

/**
 * Login component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  // components: {
  //   Layout,
  // },
  data() {
    return {
      username: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      baseUrl: process.env.VUE_APP_BACKEND_URL_VERSION,
      configuration: [],
    };
  },
  validations: {
    username: {
      required: helpers.withMessage("username is required", required),
    },
  },
  computed: {
    // ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  mounted() {
    let self = this;

    if (localStorage.getItem("session")) {
      self.$router.push({ name: "default" });
    }
    if (localStorage.getItem("reloaded")) {
      localStorage.removeItem("reloaded");
    } else {
      localStorage.setItem("reloaded", "1");
      location.reload();
    }

    axios
      .get(
        process.env.VUE_APP_BACKEND_URL_VERSION +
        "/api/master/konfig"
      )
      .then((response) => {
        var response_data = response.data;
        if (response_data.code == 200) {
          let clear_data_config = [];
          $.each(response_data.list_data, function (indexInArray, valueOfElement) {
            clear_data_config['LOGO'] = valueOfElement[0].value;
            clear_data_config['LOGIN_BACKGROUND'] = valueOfElement[1].value;
            clear_data_config['TITLE_APPLICATION'] = valueOfElement[2].value;
            clear_data_config['APPLICATION'] = valueOfElement[3].value;
            clear_data_config['VERSION'] = valueOfElement[4].value;
            clear_data_config['COPYRIGHT'] = valueOfElement[5].value;
            clear_data_config['TERM_CONDITION'] = valueOfElement[6].value;
            clear_data_config['CATATAN_CV'] = valueOfElement[7].value;
          });

          self.configuration = clear_data_config
        }
      });
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      let self = this;
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {
        var FormData = require("form-data");
        var data = new FormData();
        data.append("username", this.username);
        data.append("password", this.password);

        var config = {
          method: "post",
          url:
            process.env.VUE_APP_BACKEND_URL_VERSION + "/api/account/act_login",
          data: data,
        };
        axios(config).then((response) => {
          var data_login = response.data;
          console.log(data_login);
          //console.log(data_login);
          // check status login
          if (data_login.code != 200) {
            Swal.fire({
              icon: "error",
              title: data_login.error,
              text: data_login.message,
            });
          } else {
            Cookies.set('session', JSON.stringify(data_login.user), {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            Cookies.set('id_user', data_login.user_id, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });

            Cookies.set('session_username', data_login.user.username, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            Cookies.set('session_nama', data_login.user.name, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            Cookies.set('session_id', data_login.user.id, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            Cookies.set('session_role_id', data_login.user.role_id, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            Cookies.set('session_role_slug', data_login.user.role_slug, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            Cookies.set('session_departemen', data_login.user.departemen, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            Cookies.set('session_jabatan', data_login.user.jabatan, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            Cookies.set('session_data', data_login.user.session_id, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            Cookies.set('session_role_akses_menu', data_login.role_akses, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            Cookies.set('access_token', data_login.token, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            localStorage.reload_already = "no";
            localStorage.dateLogin = Date.now();

            // session configuration
            Cookies.set('LOGO', self.configuration.LOGO, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            // session configuration
            Cookies.set('LOGIN_BACKGROUND', self.configuration.LOGIN_BACKGROUND, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            // session configuration
            Cookies.set('TITLE_APPLICATION', self.configuration.TITLE_APPLICATION, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            // session configuration
            Cookies.set('APPLICATION', self.configuration.APPLICATION, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            // session configuration
            Cookies.set('VERSION', self.configuration.VERSION, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            // session configuration
            Cookies.set('COPYRIGHT', self.configuration.COPYRIGHT, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            // session configuration
            Cookies.set('TERM_CONDITION', self.configuration.TERM_CONDITION, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });
            // session configuration
            Cookies.set('CATATAN_CV', self.configuration.CATATAN_CV, {
              expires: 1,
              secure: true, // Ensure cookie is sent over HTTPS
              sameSite: 'Lax' // Adjust according to your needs
            });

            //GET API TOKEN BRIGATE
            var data = JSON.stringify({
              "client_id": "cc68c166-56cc-4438-a56b-66d1aa7a0ba8",
              "client_secret": "29216eb9-a802-47fd-9aad-31f6a46c19d9",
              "grant_type": "client_credentials",
              "scope": "uidigicorsec"
            });

            var config = {
              method: 'post',
              url: 'https://brigate.privateopen.sandbox.bri.co.id/gateway/Oauth2/1.0/accessToken',
              headers: {
                'Content-Type': 'application/json'
              },
              data: data
            };

            axios(config)
              .then(function (response) {
                var data_login = response.data;
                Cookies.set('access_token_brigate', data_login.access_token, {
                  expires: 1,
                  secure: true, // Ensure cookie is sent over HTTPS
                  sameSite: 'Lax' // Adjust according to your needs
                });
              })
              .catch(function (error) {
                console.log(error);
              });
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman dashboard segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */

              // return false;
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
                self.$router.push({ name: "default" });
              }
            });
          }
        });
      }
    },
  },
};
</script>
<style>
.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.bg-login {
  max-width: 100%;
  height: auto;
  padding-top: 25px;
  padding-bottom: 30px;
}
</style>
<template>
  <div class="limiter">
    <div
      class="container-login100"
      v-bind:style="{
        background: 'url(' + configuration.LOGIN_BACKGROUND + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }"
    >
      <div
        class="wrap-login100"
        style="background: #fff !important"
      >
        <b-form
          class="p-2"
          @submit.prevent="tryToLogIn"
        >
          <span class="login100-form-logo">
            <img
              :src="configuration.LOGO"
              width="150"
            />
          </span>
          <span class="login100-form-title p-b-15 p-t-15 mb-3">
            <b style="font-size: 23px !important;">{{ configuration.TITLE_APPLICATION }}</b>
          </span>
          <br>
          <b-form-group
            class="mb-3"
            id="input-group-1"
            label-for="input-1"
          >
            <label style="font-weight:900;">PN / Username</label>
            <b-form-input
              id="input-1"
              v-model="username"
              type="text"
              placeholder="Enter PN / Username"
              :class="{
                'is-invalid': submitted && v$.username.$error,
              }"
            ></b-form-input>
            <div
              v-for="(item, index) in v$.username.$errors"
              :key="index"
              class="invalid-feedback"
            >
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </b-form-group>

          <b-form-group
            class="mb-3"
            id="input-group-2"
            label-for="input-2"
          >
            <label style="font-weight:900;">Password</label>
            <b-form-input
              id="input-2"
              v-model="password"
              type="password"
              placeholder="Enter password"
            ></b-form-input>
          </b-form-group>
          <div class="mt-3 d-grid">
            <button
              class="btn btn-md btn-block btn-primary shadow"
              style="font-weight: 900 !important;"
            >
              <i class="fa fa-sign-in"></i> Sign In
            </button>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
